var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ca-header',{attrs:{"heading":"Benutzer","column-layout":true},scopedSlots:_vm._u([{key:"buttonSpace",fn:function(){return [(_vm.$can('createUsers'))?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{name: 'UserCreate' }}},[_vm._v(" Neuen Nutzer hinzufügen ")]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"container-fluid px-4 pt-4"},[_c('data-table',{attrs:{"fields":_vm.fields,"service":"users","title":"Benutzer","base-query":_vm.baseQuery},on:{"row-clicked":function (item) { return _vm.$router.push({name: 'UserEdit', params: {userId: item._id}}); }},scopedSlots:_vm._u([{key:"lastname",fn:function(ref){
var user = ref.item;
return [_vm._v(" "+_vm._s(user.firstname)+" "+_vm._s(user.lastname)+" ")]}},{key:"products",fn:function(ref){
var products = ref.value;
var user = ref.item;
return _vm._l((products),function(product,i){return _c('span',{key:user._id + 'product' + product._id},[(i > 0)?_c('span',[_c('br')]):_vm._e(),_vm._v(_vm._s(product.hn)+" ")])})}},{key:"agreements",fn:function(ref){
var user = ref.item;
return [_c('td',[(user.agreements && user.agreements[0])?_c('div',_vm._l((_vm.aggregateAgreements(user.agreements)),function(agreement){return _c('p',{key:agreement.name},[(user.agreements[0].accepted)?_c('span',[_c('i',{staticClass:"fa fa-check text-success"}),_vm._v(" Angenommen ("+_vm._s(agreement.name)+") ")]):_vm._e(),(!user.agreements[0].accepted)?_c('span',[_c('i',{staticClass:"fa fa-times text-danger"}),_vm._v(" Abgelehnt ("+_vm._s(agreement.name)+") ")]):_vm._e(),_c('small',[_vm._v(" "+_vm._s(_vm._f("formatDate")(user.agreements[0].createdAt,'DD.MM.YYYY HH:mm')))]),_c('br')])}),0):_vm._e()])]}},{key:"active",fn:function(ref){
var user = ref.item;
return [_vm._v(" "+_vm._s(user.active && !user.pending ? "Aktiv" : "Inaktiv")+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }