<template>
  <div>
    <ca-header
      heading="Benutzer"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          v-if="$can('createUsers')"
          class="btn btn-primary"
          :to="{name: 'UserCreate' }"
        >
          Neuen Nutzer hinzufügen
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="users"
        title="Benutzer"
        :base-query="baseQuery"
        @row-clicked="(item) => $router.push({name: 'UserEdit', params: {userId: item._id}})"
      >
        <template
          slot="lastname"
          slot-scope="{item: user}"
        >
          {{ user.firstname }} {{ user.lastname }}
        </template>
        <template
          slot="products"
          slot-scope="{value: products, item: user}"
        >
          <span
            v-for="(product, i) of products"
            :key="user._id + 'product' + product._id"
          >
            <span v-if="i > 0"><br></span>{{ product.hn }}
          </span>
        </template>
        <template
          slot="agreements"
          slot-scope="{item: user}"
        >
          <td>
            <div v-if="user.agreements && user.agreements[0]">
              <p
                v-for="agreement of aggregateAgreements(user.agreements)"
                :key="agreement.name"
              >
                <span v-if="user.agreements[0].accepted">
                  <i class="fa fa-check text-success" /> Angenommen ({{ agreement.name }})
                </span>
                <span v-if="!user.agreements[0].accepted">
                  <i class="fa fa-times text-danger" /> Abgelehnt ({{ agreement.name }})
                </span>
                <small> {{ user.agreements[0].createdAt | formatDate('DD.MM.YYYY HH:mm') }}</small>
                <br>
              </p>
            </div>
          </td>
        </template>
        <template
          slot="active"
          slot-scope="{item: user}"
        >
          {{ user.active && !user.pending ? "Aktiv" : "Inaktiv" }}
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable'
import formatDate from '../../filters/formatDate.js'
import roles from '@/resources/enums/roles.js'
export default {
  name: 'UserList',
  components: {
    DataTable,
    CaHeader
  },
  filters: {
    formatDate
  },
  data: () => ({
    fields: [
      {
        label: 'Benutzer',
        key: 'traderId'
      },
      {
        label: 'Name',
        key: 'lastname'
      },
      {
        label: 'E-Mail',
        key: 'email'
      },
      {
        label: 'Vermittlernummer',
        key: 'agentNumber'
      },
      {
        label: 'Bezahlart',
        key: 'payment'
      },
      {
        label: 'Benutzerrolle',
        key: 'account.roleId',
        formatter: (role) => roles[role]?.human
      },
      {
        label: 'Erstellt am',
        key: 'createdAt',
        formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
        filter: {
          type: 'DATERANGE'
        }
      },
      {
        label: 'Status',
        key: 'active'
      }
    ]
  }),
  computed: {
    userId () {
      const userIdFromParams = this.$route.params.userId
      if (!userIdFromParams) return
      if (userIdFromParams === 'me') {
        return this.$store.getters['auth/user'].user._id
      } else {
        return userIdFromParams
      }
    },
    orderId () {
      const orderId = this.$route.params.orderId
      return orderId
    },
    baseQuery () {
      return {
        userId: this.userId,
        orderId: this.orderId
      }
    }
  },
  methods: {
    aggregateAgreements (agreements) {
      // filter the agreements to only include the last agreement of each name
      const agreementsLastStatus = []
      for (const agreement of agreements) {
        const agreementAlreadyAdded = agreementsLastStatus.find(seenAgreements => seenAgreements.name === agreement.name)
        if (!agreementAlreadyAdded) {
          agreementsLastStatus.push(agreement)
        }
      }
      return agreementsLastStatus
    }
  }
}
</script>

<style scoped lang="scss">

</style>
